
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { AxiosInstance } from "axios";
import { AuthClient, UserClient } from "@/api/server";
// import axios from "axios";
// import { mapMutations } from "vuex";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("email", {
  ...email,
  message: "A valid email is required",
});
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Login extends Vue {
  private axiosInstance!: AxiosInstance;
  private client: AuthClient = new AuthClient();
  public defaultItem = {
    email: null,
    password: null,
  };
  public editedItem = {
    email: null,
    password: null,
  };
  public loading = false;
  public apiUrl = process.env.VUE_APP_API_BASE_URL;

  public login(): void {
    this.loading = true;
    UserClient.login(this.editedItem)
      .then(() => {
        localStorage.setItem("auth", "true");
        this.$router.push({ name: "employees.index" });
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    // this.client
    //   .login(this.editedItem)
    //   .then(() => {
    //     alert("Logged in");
    //   })
    //   .catch((error: { response: { data: { errors: any; message: any } } }) => {
    //     console.log(error);
    //   });
    // this.client
    //   .login(this.editedItem)
    //   .catch((error: { response: { data: { errors: any; message: any } } }) => {
    //     console.log(error);
    //   });
    // this.client.login(this.editedItem).then((result) => {
    //   console.log(result);
    // });
    // this.loading = true;
    // axios
    //   .post("http://localhost:8001/login", this.editedItem)
    //   .then((response: any) => {
    //     console.log(response.data);
    //     this.$store.commit("setUserData", response.data.user);
    //     this.loading = false;
    //     window.location = response.data.url;
    //     /**
    //      * Switch to routing below once roles and permissions are in state
    //      * and pulled from there to determine UI updates
    //      */
    //     /*this.$router.push(
    //                     {
    //                         path: response.data.roles.includes("admin") ? "/admin" : "/users",
    //                         query: this.otherQuery
    //                     },
    //                     onAbort => {}
    //                 );*/
    //   })
    //   .catch((error: { response: { data: { errors: any; message: any } } }) => {
    //     console.log(error);
    //     this.loading = false;
    //     this.editedItem = this.defaultItem;
    //     // this.$refs.form.setErrors(error.response.data.errors);
    //     if (error.response.data.message) {
    //       this.$store.commit({
    //         type: "flashAlert",
    //         color: "error",
    //         message: error.response.data.message,
    //         toggle: true,
    //       });
    //       this.$store.commit({
    //         type: "snackbar",
    //         color: "error",
    //         message: error.response.data.message,
    //         toggle: true,
    //       });
    //     }
    //   });
    return;
  }
  /**
   * Watch
   */
  //   watch: {
  //     $route: {
  //       handler: function (route) {
  //         const query = route.query;
  //         if (query) {
  //           this.redirect = query.redirect;
  //           this.otherQuery = this.getOtherQuery(query);
  //         }
  //       },
  //       immediate: true,
  //     },
  //   },
  /**
   * Methods
   */
  //   methods: {
  // ...mapMutations(["setUserData"]),
  /**
   * Login
   */
  // public login() {
  //   this.loading = true;
  // this.$store
  //     .dispatch("login", this.editedItem)
  //     .then(response => {
  //         this.loading = false;
  //         // window.location = response.data.url;
  //     })
  //     .catch(error => {
  //         this.loading = false;
  //         this.editedItem = this.defaultItem;
  //         this.$refs.form.setErrors(error.response.data.errors);
  //     });
  //   axios
  //     .post("/login", this.editedItem)
  //     .then((response) => {
  //       /*console.log(response.data);*/
  //       this.$store.commit("setUserData", response.data.user);
  //       this.loading = false;
  //       window.location = response.data.url;
  /**
   * Switch to routing below once roles and permissions are in state
   * and pulled from there to determine UI updates
   */
  /*this.$router.push(
                        {
                            path: response.data.roles.includes("admin") ? "/admin" : "/users",
                            query: this.otherQuery
                        },
                        onAbort => {}
                    );*/
  //     })
  //     .catch((error) => {
  //       this.loading = false;
  //       this.editedItem = this.defaultItem;
  //       this.$refs.form.setErrors(error.response.data.errors);
  //       if (error.response.data.message) {
  //         this.$store.commit({
  //           type: "flashAlert",
  //           color: "error",
  //           message: error.response.data.message,
  //           toggle: true,
  //         });
  //         this.$store.commit({
  //           type: "snackbar",
  //           color: "error",
  //           message: error.response.data.message,
  //           toggle: true,
  //         });
  //       }
  //     });
  // }
  /**
   * Get other query
   */
  // getOtherQuery(query) {
  //   return Object.keys(query).reduce((acc, cur) => {
  //     if (cur !== "redirect") {
  //       acc[cur] = query[cur];
  //     }
  //     return acc;
  //   }, {});
  // }
  //   },
}
